import {
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';

import KisListItem from './KisListItem';
import React from 'react';
import Uploader from './Uploader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
        width: '100%',
    },
    download: {
        [theme.breakpoints.down('sm')]: {
            order: 2
        },
    },
    upload: {
        [theme.breakpoints.down('sm')]: {
            order: 1
        },
    },
});

class KisDocuments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            uploadDisabled: true,
        }
    }

    componentWillMount = (props) => {
        this.getDocuments();
    }


    getDocuments = () => {
        axios({
            method: 'get',
            url: `/kisapi/documents`,
            headers: {
                Authorization: JSON.parse(sessionStorage.getItem('token')),
            },
        })
            .then(response => {
                const filteredDocuments = response.data.documents.filter(document =>
                    document.type !== "HaftpflichtGegner - Kostennote" && document.type !== "HaftpflichtGegner - Storno Kostennote"
                );        
                sessionStorage.setItem('documents', JSON.stringify(filteredDocuments));
        
                this.setState({
                    documents: filteredDocuments,
                });
            })
            .catch(error => {
                if (!toast.isActive(this.toastId)) {
                    this.setState({
                        wrongLogin: true,
                    });
                    this.toastId = toast.error('Fehler bei der Anmeldung', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
    }


    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container justify='center' spacing={1} style={{ maxWidth: '960px', margin: '0 auto' }}>
                    <Grid item xs={12} lg={6} className={classes.download}>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='h5' style={{ margin: '10px 0 20px 0' }}>Dokumente ansehen</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ margin: '10px 0' }}>
                                    <Typography >Folgende Datei(en) stehen für den Download bereit:</Typography>
                                </Grid>
                                {this.state.documents.map((doc, i) => {
                                    return <KisListItem doc={doc} i={i} key={doc.url} />
                                })}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={6} className={classes.upload}>
                        <Paper className={classes.paper}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='h5' style={{ margin: '10px 0 20px 0' }}>Dokumente senden</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Uploader uploaded={this.getDocuments}></Uploader>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles)(KisDocuments));